import './SectionA.scss';

import { b2x } from '@b2x/react/src';

import { BoxB } from './BoxB';
import { SectionAContentSection } from './contentTypes';
import { ProductsSlider } from './slider/ProductsSlider';

export interface SectionAProps extends SectionAContentSection {}

export const SectionA = ({ banner, products, title }: SectionAProps) => {
  return (
    <section className="my-5 section-a">
      <b2x.Container>
        <h1 className="text-center text-uppercase fw-bold my-4">{title}</h1>
        <b2x.Row>
          <b2x.Col display={{ xl: 'block', xs: 'none' }} size={3}>
            <BoxB {...banner} />
          </b2x.Col>
          <b2x.Col size={{ xl: 9, xs: 12 }}>
            {products && (
              <b2x.ProductsByIds ids={products.map(({ id }) => id)}>
                {(resolvedProducts) => resolvedProducts && <ProductsSlider products={resolvedProducts} />}
              </b2x.ProductsByIds>
            )}
          </b2x.Col>
        </b2x.Row>
        <b2x.Div display={{ xl: 'none' }} marginY={4} textAlign="center">
          <b2x.CtaFromContent {...banner?.cta} variant="primary" />
        </b2x.Div>
      </b2x.Container>
    </section>
  );
};
