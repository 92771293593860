import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { Page } from './Page';

export interface TestPageProps {}

export const TestPage = (props: TestPageProps) => {
  return (
    <Page>
      <Container>
        <b2x.LottieElement src={b2x.getBcomFilePath('2024/08/23/292627.txt')} />
      </Container>
    </Page>
  );
};
