import './MessageBoxPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Page } from './Page';
export interface MessageBoxPageProps {
  alignItems:
    | 'align-items-start'
    | 'align-items-end'
    | 'align-items-center'
    | 'align-items-baseline'
    | 'align-items-stretch';
  children: React.ReactNode;
  img?: b2x.contentTypes.ImageContentSectionV1;
  justifyContent:
    | 'justify-content-start'
    | 'justify-content-end'
    | 'justify-content-center'
    | 'justify-content-between'
    | 'justify-content-around'
    | 'justify-content-evenly';
}

export const MessageBoxPage = ({ alignItems, children, img, justifyContent }: MessageBoxPageProps) => {
  return (
    <Page backgroundImage={img} className="message-box-page" noPaddingBottom noPaddingTop>
      <div className={classnames('message-box-content d-flex ', alignItems, 'h-100')}>
        <div className="container-lg">
          <div className={classnames('row', justifyContent)}>
            <div className="col-12 col-lg-6 col-xl-5">
              <div className="rounded bg-white text-center my-5">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
