import { b2x } from '@b2x/react/src';

import { MiscellaneousContentType } from './contentTypes';
import { NewsletterForm } from './forms/NewsletterForm';

export interface NewsletterProps {}
export const Newsletter = () => {
  const content = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');

  return (
    <section
      className="newsletter-section"
      style={
        content?.body.bgColorNewsletter
          ? {
              backgroundColor: content.body.bgColorNewsletter,
            }
          : undefined
      }
    >
      <b2x.Container>
        <b2x.Row>
          <b2x.Col className="order-lg-2" size={{ lg: 5, xs: 12 }}>
            <div className="mt-4 mt-lg-5 text-white px-3">
              {content?.body.titleNewsletter && (
                <h1 className="fw-bold pt-lg-3">{b2x.formatHtml(content.body.titleNewsletter)}</h1>
              )}
              {content?.body.subtitleNewsletter && (
                <div className="mb-3 fs-4">{b2x.formatHtml(content.body.subtitleNewsletter)}</div>
              )}
              <NewsletterForm className="mb-4 text-white" emailFieldStyle="inputGroupFromLg" source="website-section" />
            </div>
          </b2x.Col>
          <b2x.Col className="order-lg-1" size={{ lg: 6, xs: 12 }}>
            {content?.body.imageNewsletter && content.body.imageNewsletter.src?.xs && (
              <div className="d-flex h-100 align-items-end">
                <b2x.ImageFromContentV1 fluid {...content.body.imageNewsletter} />
              </div>
            )}
          </b2x.Col>
        </b2x.Row>
      </b2x.Container>
    </section>
  );
};
