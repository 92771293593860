import './SectionLanding.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { LandingContentSection } from './contentTypes';
import { Section } from './Section';
import { SectionLandingProductsSlider } from './slider/SectionLandingProductsSlider';

export interface SectionLandingProps extends LandingContentSection {}

export const SectionLanding = ({ elementRowList }: SectionLandingProps) => {
  return (
    <>
      {elementRowList &&
        elementRowList.map((row) => (
          <Section className="section-landing my-3 my-xl-5" key={row.contentSectionId}>
            {(row.title || row.subtitle) && (
              <b2x.Row className="justify-content-center">
                <b2x.Col size={{ lg: 10 }}>
                  <div className="text-center my-3">
                    <h2 className="fw-semi-bold">{b2x.formatHtml(row.title)}</h2>
                    <h6 className="mt-3">{b2x.formatHtml(row.subtitle)}</h6>
                  </div>
                </b2x.Col>
              </b2x.Row>
            )}
            {row.columnContent && (
              <b2x.Row
                className={classnames(
                  {
                    'align-items-center': row.columnContent.productsList && row.columnContent.productsList.length === 0,
                  },
                  {
                    ' mt-3': row.columnContent.productsList && row.columnContent.productsList.length > 0,
                  }
                )}
              >
                <b2x.Col
                  className={classnames({ 'order-lg-2': row.columnContent.assetPosition === 'right' })}
                  size={{ lg: row.columnContent.assetSize === 'bigger' ? 7 : 6, xs: 12 }}
                >
                  {row.columnContent.asset && (
                    <div
                      className={classnames(
                        {
                          'd-lg-block d-none':
                            row.columnContent.productsList && row.columnContent.productsList.length === 0,
                        },
                        {
                          ' mb-4': row.columnContent.productsList && row.columnContent.productsList.length > 0,
                        }
                      )}
                    >
                      {row.columnContent.productsList && row.columnContent.productsList.length > 0 ? (
                        row.columnContent.cta ? (
                          <div className="position-relative">
                            <b2x.DeprecatedCta cta={row.columnContent.cta}>
                              <b2x.AssetV1 {...row.columnContent.asset} fluid />
                            </b2x.DeprecatedCta>
                            <div className="position-absolute asset-cta">
                              <b2x.CtaFromContent {...row.columnContent.assetCta} variant="primary" />
                            </div>
                          </div>
                        ) : (
                          <b2x.AssetV1 {...row.columnContent.asset} className="rounded" fluid />
                        )
                      ) : row.columnContent.cta ? (
                        <b2x.DeprecatedCta cta={row.columnContent.cta}>
                          <b2x.AssetV1 {...row.columnContent.asset} className="rounded" fluid />
                        </b2x.DeprecatedCta>
                      ) : (
                        <b2x.AssetV1 {...row.columnContent.asset} className="rounded" fluid />
                      )}
                    </div>
                  )}
                </b2x.Col>
                <b2x.Col
                  className={classnames(
                    { 'order-lg-1': row.columnContent.assetPosition === 'right' },
                    {
                      'px-0 ps-3 ps-lg-0': row.columnContent.productsList && row.columnContent.productsList.length > 0,
                    },
                    { 'px-xl-5': row.columnContent.productsList && row.columnContent.productsList.length === 0 }
                  )}
                  size={{ lg: row.columnContent.assetSize === 'bigger' ? 5 : 6, xs: 12 }}
                >
                  {row.columnContent.productsList && row.columnContent.productsList.length > 0 ? (
                    <b2x.ProductsByIds ids={row.columnContent.productsList.map(({ productId }) => productId)}>
                      {(products) =>
                        products !== undefined && (
                          <b2x.EqualHeight>
                            <SectionLandingProductsSlider products={products} />
                          </b2x.EqualHeight>
                        )
                      }
                    </b2x.ProductsByIds>
                  ) : (
                    <div>
                      {row.columnContent.title && (
                        <h2 className={classnames('text-lg-start text-center fw-semi-bold')}>
                          {b2x.formatHtml(row.columnContent.title)}
                        </h2>
                      )}
                      {row.columnContent.preTitle && (
                        <p className="mt-1 text-lg-start text-center">{b2x.formatHtml(row.columnContent.preTitle)}</p>
                      )}
                      {row.columnContent.asset && (
                        <div className="d-lg-none mb-3">
                          {row.columnContent.productsList && row.columnContent.productsList.length > 0 ? (
                            row.rowCta ? (
                              <b2x.DeprecatedCta cta={row.rowCta}>
                                <b2x.AssetV1 {...row.columnContent.asset} className="rounded" fluid />
                              </b2x.DeprecatedCta>
                            ) : (
                              <b2x.AssetV1 {...row.columnContent.asset} className="rounded" fluid />
                            )
                          ) : row.columnContent.cta ? (
                            <b2x.DeprecatedCta cta={row.columnContent.cta}>
                              <b2x.AssetV1 {...row.columnContent.asset} className="rounded" fluid />
                            </b2x.DeprecatedCta>
                          ) : (
                            <b2x.AssetV1 {...row.columnContent.asset} className="rounded" fluid />
                          )}
                        </div>
                      )}
                      {row.columnContent.description && (
                        <div className="mt-lg-4 mt-3">{b2x.formatHtml(row.columnContent.description)}</div>
                      )}
                      {row.columnContent.cta && (
                        <b2x.Div className="d-grid gap-2 d-lg-block">
                          <b2x.CtaFromContent
                            ctaProps={{ button: { className: 'mt-lg-5 mt-4 btn-block px-5' } }}
                            {...row.columnContent.cta}
                          />
                        </b2x.Div>
                      )}
                    </div>
                  )}
                </b2x.Col>
              </b2x.Row>
            )}
            {row.rowCta && (
              <div
                className={classnames('text-center my-5', {
                  'px-0 px-xl-3': row.columnContent?.productsList && row.columnContent.productsList.length > 0,
                })}
              >
                <b2x.DeprecatedCta
                  className="text-decoration-underline fw-semi-bold text-uppercase d-block d-lg-none"
                  cta={row.rowCta}
                >
                  {b2x.formatHtml(row.rowCta.label)}
                </b2x.DeprecatedCta>
                <b2x.CtaFromContent
                  {...row.rowCta}
                  ctaProps={{ button: { className: 'px-5 d-none d-lg-inline-block' } }}
                />
              </div>
            )}
          </Section>
        ))}
    </>
  );
};
