import classnames from 'classnames';
import React from 'react';

// import { useStatePromise } from 'use-state-promise';
import { getBreakpointValue, getImageSrc, renderUI } from './util';

export interface ImageFromSrcProps extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> {
  alt?: string;
  className?: string;
  fluid?: boolean;
  // height?: number | string;
  src?: SrcSet | ResponsiveSrcSet;
  title?: string;
  // width?: number | string;
}

interface ResponsiveSrcSet {
  lg?: SrcSet;
  md?: SrcSet;
  sm?: SrcSet;
  xl?: SrcSet;
  xs: SrcSet;
  xxl?: SrcSet;
}

type SrcSet = Array<{ density: 1 | 1.5 | 2; src: string }>;

export const ImageFromSrc = ({ className, fluid, src, ...otherProps }: ImageFromSrcProps) => {
  const sourcesBreakpoint: Array<keyof ResponsiveSrcSet> = ['xxl', 'xl', 'lg', 'md', 'sm'];

  const getSrc = React.useCallback((srcSet: SrcSet) => {
    return getImageSrc(srcSet.at(0)?.src);
  }, []);

  const getSrcFromBreakpoint = React.useCallback(
    (breakpoint: keyof ResponsiveSrcSet) => {
      if (Array.isArray(src)) {
        throw new Error(
          'Trying to get image source from breakpoint, but the src is a simple array (non-responsive image)'
        );
      }
      const breakpointSrcSet = src && src[breakpoint];
      return breakpointSrcSet && getSrc(breakpointSrcSet);
    },
    [getSrc, src]
  );

  const getSrcSet = React.useCallback((srcSet: SrcSet) => {
    return srcSet.map((item) => `${getImageSrc(item.src)} ${item.density}x`).join(', ');
  }, []);

  const getSrcSetFromBreakpoint = React.useCallback(
    (breakpoint: keyof ResponsiveSrcSet) => {
      if (Array.isArray(src)) {
        throw new Error(
          'Trying to get image srcset from breakpoint, but the src is a simple array (non-responsive image)'
        );
      }
      const breakpointSrcSet = src && src[breakpoint];
      return breakpointSrcSet && getSrcSet(breakpointSrcSet);
    },
    [getSrcSet, src]
  );

  return (
    <>
      <picture>
        {!Array.isArray(src) &&
          sourcesBreakpoint.map(
            (sourceBreakpoint) =>
              getSrcSetFromBreakpoint(sourceBreakpoint) && (
                <source
                  key={sourceBreakpoint}
                  media={`(min-width: ${getBreakpointValue(sourceBreakpoint)}px)`}
                  srcSet={getSrcSetFromBreakpoint(sourceBreakpoint)}
                />
              )
          )}
        {renderUI({
          bs5: (
            // eslint-disable-next-line react/forbid-elements, jsx-a11y/alt-text
            <img
              {...otherProps}
              className={classnames({ 'img-fluid': fluid }, className)}
              src={!Array.isArray(src) ? getSrcFromBreakpoint('xs') : Array.isArray(src) ? getSrc(src) : undefined}
              srcSet={
                !Array.isArray(src) ? getSrcSetFromBreakpoint('xs') : Array.isArray(src) ? getSrcSet(src) : undefined
              }
            />
          ),
        })}
      </picture>
    </>
  );
};
