import './PopupNewsletterModal.scss';

import { b2x } from '@b2x/react/src';

import { NewsletterContentType } from '../contentTypes';
import { NewsletterForm } from '../forms/NewsletterForm';

interface PopUpNewsletterModalProps extends b2x.ModalProps {}

const PopUpNewsletterModal = (modalProps: PopUpNewsletterModalProps) => {
  const content = b2x.useContent<NewsletterContentType>('NEWSLETTER_CONTENT');

  return (
    <b2x.Modal {...modalProps} className="popup-newsletter-modal">
      <div className="rounded-1 bg-light-blue">
        <b2x.ModalHeader title="" />
        <b2x.ModalBody className="px-3 ">
          <div style={{ marginTop: '-1rem' }}>
            <h3 className="fw-semi-bold text-center text-white">{b2x.formatHtml(content?.body.title)}</h3>
            <div className="text-center bg-white p-3 rounded-3 mt-3">
              <div className="mt-3 mb-3">{b2x.formatHtml(content?.body.content)}</div>
              <NewsletterForm
                ctaContainerClassName="text-center my-3"
                emailFieldStyle="textInput"
                source="website-modal"
              />
            </div>
            {content?.body.asset && (
              <div className="text-center">
                <b2x.AssetV1 {...content.body.asset} fluid />
              </div>
            )}
          </div>
        </b2x.ModalBody>
      </div>
    </b2x.Modal>
  );
};

export const usePopUpNewsletterModal = (props?: PopUpNewsletterModalProps) =>
  b2x.useStaticModal(PopUpNewsletterModal, 'modal', props);
