import './BannerA.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { BannerAContentSection } from './contentTypes';

export interface BannerAProps extends BannerAContentSection {}

export const BannerA = ({
  background,
  columnImage,
  content,
  lottie,
  textAlignment,
  textColor,
  title,
}: BannerAProps) => {
  return (
    <section className="banner-a my-5 pb-5 position-relative">
      <b2x.Container className="position-relative">
        {background && (
          <b2x.Row className="align-items-center position-relative">
            {lottie && lottie.src && background.type === 'image' && background.image && background.image.src && (
              <div className="lottie-item position-absolute bottom-0 d-none d-lg-block h-100 mx-auto">
                <b2x.LottieElement height="100%" src={lottie.src} />
              </div>
            )}
            <b2x.Col size={12}>
              {background.type === 'image' && background.image && background.image.src && (
                <b2x.ImageFromContentV1 {...background.image} fluid />
              )}
              {background.type === 'video' && background.video && b2x.isVideoFromContentValid(background.video) && (
                <b2x.VideoFromContentV1 {...background.video} />
              )}
            </b2x.Col>
          </b2x.Row>
        )}

        <b2x.Row className="position-absolute top-0 w-100 h-100 align-items-center banner-a-copy">
          <b2x.Col size={{ lg: 6, xl: 5, xs: 12 }}>
            <b2x.Div
              className={classnames(
                'pe-lg-5 px-3 px-sm-4 px-md-5 pt-5 pt-lg-0',
                `text-${textColor}`,
                `text-lg-${textAlignment}`
              )}
            >
              <div className="">
                {title && <h3 className="h1 fw-semi-bold">{b2x.formatHtml(title)}</h3>}
                {content && (
                  <>
                    <div className="fs-4 d-block d-md-none">{b2x.formatHtml(content)}</div>
                    <div className="fs-2 d-none d-md-block">{b2x.formatHtml(content)}</div>
                  </>
                )}
              </div>
            </b2x.Div>
          </b2x.Col>
          <b2x.Col className="offset-lg-1 order-lg-first" size={{ lg: 5, xl: 5, xs: 12 }}>
            <div className="text-center">{columnImage && <b2x.ImageFromContentV1 {...columnImage} fluid />}</div>
          </b2x.Col>
        </b2x.Row>
      </b2x.Container>
    </section>
  );
};
