import { b2x } from '@b2x/react/src';

export interface QuantityInputGroupProps {
  decreaseButton: b2x.ButtonProps<string, string, number>;
  increaseButton: b2x.ButtonProps<string, string, number>;
  quantity: b2x.formikNumber;
  size?: number;
}

export const QuantityInputGroup = ({
  decreaseButton,
  increaseButton,
  quantity,
  size = 40,
}: QuantityInputGroupProps) => {
  return (
    <b2x.Div alignItems="stretch" display="flex" style={{ height: size }}>
      <b2x.Button
        {...decreaseButton}
        className="p-0"
        iconStart={{ name: 'minus', size: 14 }}
        label={undefined}
        style={{ width: size }}
        variant="gray-200"
      />
      <input
        className="form-control border-0 text-center fw-bold bg-transparent p-0"
        readOnly
        style={{ width: 45 }}
        type="text"
        value={quantity}
      />
      <b2x.Button
        {...increaseButton}
        className="p-0"
        iconStart={{ name: 'plus', size: 14 }}
        label={undefined}
        style={{ width: size }}
        variant="gray-200"
      />
    </b2x.Div>
  );
};
